import React from "react";
import {BrowserRouter as Router, Routes , Route, Link} from "react-router-dom";
// import Stake from "./pages/Stake";
// import Harvester from "./pages/Harvester";
// import Claim from "./pages/Claim";
// import Reader from "./pages/Reader";
import PulseDropSnapshot from "./pages/pulseDropSnapshot";

function App() {

  return (
    <Router>
        <Routes>
          <Route path="/" element={<PulseDropSnapshot/>} />
            {/* <Route path="/stake" element={<Stake/>} />
            <Route path="/harvester" element={<Harvester/>} />
            <Route path="/claim" element={<Claim/>} />
           <Route path="/reader" element={<Reader/>} /> */}
           <Route path="/pulsedrop" element={<PulseDropSnapshot/>} />
      </Routes>
    </Router>
  );
}

export default App;
