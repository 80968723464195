import React, { useEffect, useState, useCallback } from 'react';
import "./snapshot.css";
import "../App.css";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/pulsedrop.jpg";
import snapshot_abi from "../abi/snapshot_abi.json";
import abi from "../abi/abi.json";
import { ethers } from "ethers";
import Layout from './Layout';
import { useMediaQuery } from 'react-responsive';
import Web3Modal from 'web3modal';
import { providerOptions } from '../tools/providerOptions';
import { Link } from 'react-router-dom';

// Screen Resolution
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 });
  return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return isMobile ? children : null;
};

function PulseDropSnapshot() {
  const [account, setAccount] = useState("");
  const [provider, setProvider] = useState("");
  const [signer, setSigner] = useState("");
  const [balance, setBalance] = useState("");
  const [loading, setLoading] = useState(false); 
  const [showGift, SetShowGift] = useState(false);
  const [eligibility, SetEligibility] = useState(true);
  const [allocation, setAllocation] = useState("");  
  const [entitlement, setEntitlement] = useState(""); 
  const contractAddress = "0x09FD3D6e6889940Ca1158B9221309Bd69faFa32b";
  const snapshotAddress = "0xD0ADfD23d010CA13058897eC358524d34a342081"; //snapshot
  const STAKERS_CLAIM = "207000000000000";

   // Web3Modal Constructor
   const web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: false, // optional
    theme: "dark",
    providerOptions, // required
  });

  const initConnection = async () => {

    try {

      const provider = await web3Modal.connect();
      const tempProvider = new ethers.providers.Web3Provider(provider);
      const accounts = await tempProvider.listAccounts();
      const signer = tempProvider.getSigner();

      const networkId = await tempProvider.provider.request({
        method: "net_version",
      });

      if (networkId !== 42161) {
        await tempProvider.provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: "0xA4B1" }],
        });
      }

      setProvider(tempProvider);
      if (accounts) setAccount(accounts[0]);
      setSigner(signer);

    } catch (err) {

      if (
        typeof err !== "undefined" &&
        typeof err.message !== "undefined" &&
        err.message.includes("User Rejected")
        ) {
          console.log("User rejected the request");
          } else if (
          (typeof err === "string" || err instanceof String) &&
          console.log("Modal closed by user")
          ) {
          console.log("Modal closed by user");
      } else {
        console.log("Something went wrong.");
      }
      
    }
  }

  const bal = useCallback(async () => {    
    const contractERC = new ethers.Contract(
      contractAddress,
      abi,
      provider
    );   
  
    const tempBal = await contractERC.balanceOf(account);
    setBalance(tempBal.toString());
  }, [account]);
  
  const allbalances = () => {
      bal();
  };

  const disconnectWallet = () => {
    web3Modal.clearCachedProvider();
    localStorage.clear();
    loadState();
  };

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        console.log("accountsChanged", accounts);
        if (accounts) {
          setAccount(accounts[0]);
        }
      };
  
      const handleDisconnect = () => {
        console.log("disconnect");
        web3Modal.clearCachedProvider();
        localStorage.clear();
        loadState();
      };
  
      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("disconnect", handleDisconnect);
  
      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);

  const loadState = () => {
    setAccount("");
    setProvider("");
    setSigner("")
  }

  useEffect(() => {
    setLoading(true);
    bal().then(() => setLoading(false)); // load the balance on mount
  
    const intervalId = setInterval(() => {
      setLoading(true);
      bal().then(() => setLoading(false)); // refresh the balance periodically
    }, 30000); // refresh every 30 seconds
  
    return () => clearInterval(intervalId); // clear the interval on unmount
  }, [bal]);

  const handleGift = () => {
    SetShowGift(true);
  }

  useEffect(() => {
    const fetchSnapshot = async () => {
      if (!provider || !signer) return;
  
      const contractf = new ethers.Contract(snapshotAddress, snapshot_abi, signer);
      let snapbal = await contractf.Records(account);
      console.log(snapbal);
      if (snapbal > 0) {
        let allfarmbal = await contractf.allFarmersBalance();
        const allFarmersBalance = ethers.utils.formatEther(allfarmbal);
        console.log("AllfarmersBalance:", allFarmersBalance);
        const allocationFactor = allFarmersBalance / STAKERS_CLAIM;
        console.log("Allocation Factor is", allocationFactor);
        const snapbalance = ethers.utils.formatEther(snapbal);
        const result = snapbalance * allocationFactor;
        setAllocation(snapbalance);
        setEntitlement(result);
        console.log("User's Allocation:", snapbalance);
        console.log("Calculated Rewards:", result);
      } else {
        SetEligibility(false);
      };

      }
      fetchSnapshot();
    }, [provider, signer]); 

  return (
    <s.Screen image={cerberus}>
      <Desktop>
      <div>
      <div className="header">
          <Link to="/" className={"textstyle"}><img src={require(`../logo_w_txt.png`)} style={{height: "30px"}}/></Link>              
{/* ---------------------------------------------------------------------------------------------------------                */}
            {loading ? (
                  <div>Loading Balance...</div>
                ) : balance === "" ? (
                  <button className="button0" onClick={allbalances}>
                    Load Balance
                  </button>
                ) : (
                  <div style={{ color: "white" }}>
                    {(Number(balance) / 10 ** 18).toFixed()}{" "}
                    <span style={{ color: "lime" }}>$DROP</span>
                  </div>
                )}
 {/* ---------------------------------------------------------------------------------------------------                */}
          <div>
            {account == "" ? 
            <button title="Connect Wallet" onClick={initConnection} className={"button1"}>
              Connect Wallet
            </button>
            : (
              <p onClick={disconnectWallet} className="wallet" title="Disconnect Wallet">
                ...{account.substring(account.length - 5)} 
              <span><img src={require(`../assets/images/icons/logout.png`)} style={{height: "25px"}}/></span> 
              </p> 
              )}
          </div>

        </div>
      </div>

      <h1 className='snaptext' align="center">
      <img src={require(`../assets/images/photo-camera.png`)} height="20px" /> 
      <span style={{color: "whitesmoke"}}> Snapshot Contract: </span>
      <a href="https://arbiscan.io/address/0xd0adfd23d010ca13058897ec358524d34a342081"> 
      <span style={{color: "violet"}}>0xd0adfd23d010ca13058897ec358524d34a342081</span></a>
      </h1>

      {account !=='' && (
            <div className='addressdiv' align="center"> <span style={{color: "whitesmoke"}}>{account}</span>
            </div>
      )}

      
      {!showGift && allocation !=="" && (
        <div>
          <div className="gift" align="center" onClick={handleGift}>
            <img src={require(`../assets/images/gift.gif`)} height="50%" style={{marginTop: "30%"}} />
            <br />
          </div>           
          <p className='textstyle' 
          style={{marginTop: "1%", fontWeight: "bold",
          fontSize: "18px", color: "magenta"}}>
            You have one gift package!</p> 
        </div> 
      )}

      
      {showGift && (
        <div align="center">
          <img src={require(`../assets/images/congratulations.gif`)} style={{height: "20%"}} />
            <div>
                <div className='snapshotdiv' align="center">
                Balance During Snapshot: <span style={{color: "skyblue"}}>{allocation} DROP</span> staked. 
                </div>
                <div className='snapshotdiv' align="center"> 
                You're currently entitled to <span style={{color: "violet"}}>{entitlement} pulse DROP </span>tokens on <span style={{color: "violet"}}>pulsechain</span>!
                </div>
            </div>
        </div>
      )}

      {!eligibility && (
        <div align="center">         
          <img src={require(`../assets/images/sadfrog.png`)} height="250px" />
            <div className='snapshotdiv' align="center" >              
            <p style={{padding: "2px"}}>Dang, you missed the <span style={{color: "violet"}}>pulseDrop</span> snapshot! </p>
           <p style={{padding: "2px"}}> Stake <span style={{color: "skyblue"}}>Drop</span> today and don't miss <span style={{color: "limegreen"}}>SyncDrop</span> snapshot; </p>
           <p style={{padding: "4px"}}> our next <span style={{color: "limegreen"}}>memedrop</span> on <span style={{color: "limegreen"}}>ZkSyncEra!</span></p>
           <p >
            <a href="https://stake.dropnation.wtf" style={{ color: "violet" }}>Stake DropArb now!</a>
          </p>
            </div>
        </div>
      )}

      {signer == null && (
        <div align="center">         
          <img src={require(`../assets/images/anxiousfrog.png`)} height="280px" style={{marginTop: "3%"}} />
            <div className='snapshotdiv' align="center" >              
            <p style={{padding: "2px"}}>Know Your Fate! </p>
           <p style={{padding: "2px"}}> Connect Your <span style={{ color: "violet" }}>Wallet</span>, Dropper. </p>
            </div>
        </div>
      )} 
    </Desktop>  
    <Mobile>
      <div>

      <div className="mobilelogo" align="center" >
              <Link to="/" className={"textstyle"}><img src={require(`../logo_w_txt.png`)} style={{height: "30px"}}/></Link>              
          </div>
          <div className="mobilebalances" align="center">
               {loading ? (
                <div>Loading Balance...</div>
                      ) : balance === "" ? (
                        <button className="button0" onClick={bal}>
                          Load Balance
                        </button>
                      ) : (
                        <div style={{ color: "white" }}>
                          {(Number(balance) / 10 ** 18).toFixed()}{" "}
                          <span style={{ color: "lime" }}>$DROP</span>
                        </div>
                      )}                
          </div>
          <div className="mobileconnect" align="center">
                {account == "" ? 
                <button title="Connect Wallet" onClick={initConnection} className={"button1"}>
                  Connect Wallet
                </button>
                : (
                  <p onClick={disconnectWallet} className="wallet" title="Disconnect Wallet">
                    ...{account.substring(account.length - 5)} 
                  <span><img src={require(`../assets/images/icons/logout.png`)} style={{height: "25px"}}/></span> 
                  </p> 
                  )}
          </div>
      </div>

      <div className='mobileheader' align="center">  
        <h1 className="snaptextmobile">
        <img src={require(`../assets/images/photo-camera.png`)} height="20px" /><br/>
        <span style={{color: "whitesmoke"}}> Snapshot Contract: </span> <br />
        <a href="https://arbiscan.io/address/0xd0adfd23d010ca13058897ec358524d34a342081"> 
        <span style={{color: "violet"}}>0xd0adfd23d010ca13058897ec358524d34a342081</span></a>
        </h1>
      </div>  
      
      {account !=='' && (
            <div className='mobileheader addressdivmobile' align="center"> 
            <span style={{color: "whitesmoke"}}>{account}</span>
            </div>
      )}

      
      {!showGift && allocation !=="" && (
        <div>
          <div className="giftmobile" align="center" onClick={handleGift}>
            <img src={require(`../assets/images/gift.gif`)} height="100px"/>
            <br />
          </div>           
          <p className='textstyle' 
          style={{marginTop: "1%", fontWeight: "bold",
          fontSize: "18px", color: "magenta"}}>
            You have one gift package!</p> 
        </div> 
      )}

      {showGift && (
        <div className='mobileheader' align="center">
          <img src={require(`../assets/images/congratulations.gif`)} style={{height: "80px"}} />
            <div>
                <div className='snapshotdiv' align="center">
                Balance During Snapshot: <span style={{color: "skyblue"}}>{allocation} DROP</span> staked. 
                </div>
                <div className='snapshotdiv' align="center"> 
                You're currently entitled to <span style={{color: "violet"}}>{entitlement} pulse DROP </span>tokens on <span style={{color: "violet"}}>pulsechain</span>!
                </div>
            </div>
        </div>
      )}

      {!eligibility && (
        <div align="center">         
          <img src={require(`../assets/images/sadfrog.png`)} height="150px" />
            <div className='snapshotdiv' align="center" >              
            <p style={{padding: "2px"}}>Dang, you missed the <span style={{color: "violet"}}>pulseDrop</span> snapshot! </p>
           <p style={{padding: "2px"}}> Stake <span style={{color: "skyblue"}}>Drop</span> today and don't miss <span style={{color: "limegreen"}}>SyncDrop</span> snapshot; </p>
           <p style={{padding: "4px"}}> our next <span style={{color: "limegreen"}}>memedrop</span> on <span style={{color: "limegreen"}}>ZkSyncEra!</span></p>
           <p >
            <a href="https://stake.dropnation.wtf" style={{ color: "violet" }}>Stake DropArb now!</a>
          </p>
            </div>
        </div>
      )}

      {signer == null && (
        <div align="center">         
          <img src={require(`../assets/images/anxiousfrog.png`)} height="280px" style={{marginTop: "3%"}} />
            <div className='snapshotdiv' align="center" >              
            <p style={{padding: "2px"}}>Know Your Fate! </p>
           <p style={{padding: "2px"}}> Connect Your <span style={{ color: "violet" }}>Wallet</span>, Dropper. </p>
            </div>
        </div>
      )} 

    </Mobile>      

    </s.Screen>
  );
};

export default PulseDropSnapshot;