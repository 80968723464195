import React, { useEffect, useState, useCallback } from "react";
import "../App.css";
import { ethers } from "ethers";
import abi from "../abi/abi.json";
import * as s from "../styles/globalStyles";
import harvester_abi from "../abi/harvester_abi.json"; 
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Web3Modal from 'web3modal';
import { providerOptions } from '../tools/providerOptions';

// Screen Resolution
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 });
  return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return isMobile ? children : null;
};

function Layout() {
  const [account, setAccount] = useState("");
  const [provider, setProvider] = useState("");
  const [signer, setSigner] = useState("");
  const [balance, setBalance] = useState(""); 
  const [claim, setClaim] = useState("");
  const [remainingTime, setRemainingTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const contractAddress = "0x09FD3D6e6889940Ca1158B9221309Bd69faFa32b";
  const harvesterAddress = "0x588f92C0120A786a60A197350f0b153aACFaa6B2"; 

  // Web3Modal Constructor
  const web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: false, // optional
    theme: "dark",
    providerOptions, // required
  });

  const initConnection = async () => {

    try {

      const provider = await web3Modal.connect();
      const tempProvider = new ethers.providers.Web3Provider(provider);
      const accounts = await tempProvider.listAccounts();
      const signer = tempProvider.getSigner();

      const networkId = await tempProvider.provider.request({
        method: "net_version",
      });

      if (networkId !== 42161) {
        await tempProvider.provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: "0xA4B1" }],
        });
      }

      setProvider(tempProvider);
      if (accounts) setAccount(accounts[0]);
      setSigner(signer);

    } catch (err) {

      if (
        typeof err !== "undefined" &&
        typeof err.message !== "undefined" &&
        err.message.includes("User Rejected")
        ) {
          console.log("User rejected the request");
          } else if (
          (typeof err === "string" || err instanceof String) &&
          console.log("Modal closed by user")
          ) {
          console.log("Modal closed by user");
      } else {
        console.log("Something went wrong.");
      }
      
    }
  }

  const bal = useCallback(async () => {    
    const contractERC = new ethers.Contract(
      contractAddress,
      abi,
      provider
    );   
  
    const tempBal = await contractERC.balanceOf(account);
    setBalance(tempBal.toString());
  }, [account]);
  

  const allbalances = () => {
      bal();
  };


  const disconnectWallet = () => {
    web3Modal.clearCachedProvider();
    localStorage.clear();
    loadState();
  };

  useEffect (() => {
    initConnection();  
    }, []);

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        console.log("accountsChanged", accounts);
        if (accounts) {
          setAccount(accounts[0]);
        }
      };
  
      const handleDisconnect = () => {
        console.log("disconnect");
        web3Modal.clearCachedProvider();
        localStorage.clear();
        loadState();
      };
  
      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("disconnect", handleDisconnect);
  
      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);

  const loadState = () => {
    setAccount("");
    setProvider("");
    setSigner("")
  }

  useEffect(() => {
    setLoading(true);
    bal().then(() => setLoading(false)); // load the balance on mount
  
    const intervalId = setInterval(() => {
      setLoading(true);
      bal().then(() => setLoading(false)); // refresh the balance periodically
    }, 30000); // refresh every 30 seconds
  
    return () => clearInterval(intervalId); // clear the interval on unmount
  }, [bal]);

  return (
    <div>
      <Desktop>
      <div className="header">
          <Link to="/" className={"textstyle"}><img src={require(`../logo_w_txt.png`)} style={{height: "30px"}}/></Link>              
{/* ---------------------------------------------------------------------------------------------------------                */}
            {loading ? (
                  <div>Loading Balance...</div>
                ) : balance === "" ? (
                  <button className="button0" onClick={allbalances}>
                    Load Balance
                  </button>
                ) : (
                  <div style={{ color: "white" }}>
                    {(Number(balance) / 10 ** 18).toFixed()}{" "}
                    <span style={{ color: "lime" }}>$DROP</span>
                  </div>
                )}
 {/* ---------------------------------------------------------------------------------------------------                */}
          <div>
            {account == "" ? 
            <button title="Connect Wallet" onClick={initConnection} className={"button1"}>
              Connect Wallet
            </button>
            : (
              <p onClick={disconnectWallet} className="wallet" title="Disconnect Wallet">
                ...{account.substring(account.length - 5)} 
              <span><img src={require(`../assets/images/icons/logout.png`)} style={{height: "25px"}}/></span> 
              </p> 
              )}
          </div>

        </div>
      </Desktop>
      <Mobile>
          <div className="mobilelogo" align="center" >
              <Link to="/" className={"textstyle"}><img src={require(`../logo_w_txt.png`)} style={{height: "30px"}}/></Link>              
          </div>
          <div className="mobilebalances" align="center">
               {loading ? (
                <div>Loading Balance...</div>
                      ) : balance === "" ? (
                        <button className="button0" onClick={bal}>
                          Load Balance
                        </button>
                      ) : (
                        <div style={{ color: "white" }}>
                          {(Number(balance) / 10 ** 18).toFixed()}{" "}
                          <span style={{ color: "lime" }}>$DROP</span>
                        </div>
                      )}                
          </div>
          <div className="mobileconnect" align="center">
                {account == "" ? 
                <button title="Connect Wallet" onClick={initConnection} className={"button1"}>
                  Connect Wallet
                </button>
                : (
                  <p onClick={disconnectWallet} className="wallet" title="Disconnect Wallet">
                    ...{account.substring(account.length - 5)} 
                  <span><img src={require(`../assets/images/icons/logout.png`)} style={{height: "25px"}}/></span> 
                  </p> 
                  )}
          </div>
    </Mobile>
  </div>  
  );
}

export default Layout;